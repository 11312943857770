.category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100%;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    font-size: 14px;
    min-width: 100%;
  }
}

.active {
  background: var(--primary-color);
  color: var(--text-primary-color);
}
