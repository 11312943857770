.heading {
  font-weight: 500;
  font-size: 20px;
  margin: 16px 0px 8px 0px;
  color: var(--primary-text-color);
}
.description {
  font-size: var(--default-font-size);
  color: var(--secondary-text-color);
  text-align: justify;
}
.list {
  list-style: disc;
  margin: 0px 0px 0px 16px;
  li {
    list-style-position: inside;
  }
}
