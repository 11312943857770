.container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  background: rgba(255, 255, 255, 0.07);
  .text {
    position: absolute;
    inset: 0;
    padding: 0.5rem 1rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    text-shadow: 0px 0px 20px #000000;
    transition: background-color 0.3s cubic-bezier(0.22, 0.68, 0, 1.5),
      opacity 1s cubic-bezier(0.22, 0.68, 0, 1.5);
    background: linear-gradient(to top, #000000bb, #00000044 30%, transparent);
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  &:hover {
    .text {
      opacity: 1;
    }
  }
}
