.epGameGrid {
  display: grid;
  grid-gap: 1rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 100%;
  position: relative;
  grid-template-areas:
    "game game game game game game"
    "game game game game game game"
    "game game game game game game"
    "rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd"
    "text text text text text text"
    "advert advert advert advert advert advert";

  .game {
    grid-column: span 2;
    grid-row: span 1;
    &:nth-child(6n + 2) {
      grid-row: span 2;
      grid-column: span 4;
    }
  }
  .seoContent {
    grid-area: text;
  }
  .rtbAd {
    grid-area: rtbAd;
  }
  .advert {
    grid-area: advert;
    max-width: 100%;
    position: relative;
  }

  @media screen and (min-width: 768px) {
    grid-template-areas:
      "game game game game game game game game game game game game"
      "game game game game game game game game game game game game"
      "game game game game game game game game game game game game"
      "rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd rtbAd"
      "text text text text text text text text text text text text"
      "advert advert advert advert advert advert advert advert advert advert advert advert";
    .game {
      grid-column: span 2;
      grid-row: span 1;
      &:nth-child(6n + 2) {
        grid-row: span 1;
        grid-column: span 2;
      }
      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 4;
      }
      // this needs to be updated based on the number of games, if the any of the last row games is big and doesn't look good
      &:nth-child(26) {
        grid-column: span 2;
        grid-row: span 1;
      }
      //   &:nth-child(5n + 1) {
      //     grid-row: span 2;
      //     grid-column: span 2;
      //   }
      // &:nth-child(6n + 1) {
      //   grid-column: span 2;
      //   grid-row: span 1;
      // }
      // &:nth-child(13n + 1) {
      //   grid-row: span 2;
      //   grid-column: span 4;
      // }
      // &:nth-child(18n + 1) {
      //   grid-row: span 2;
      //   grid-column: span 4;
      // }
    }
  }
}
