.title {
  //   variant="normal"
  //   className={classes.title}
  //   my={[4, null, null, 6]}
  //   fontWeight="600"
  //   display="inline-flex"
  //   alignItems="center"
  //   gap={2}

  margin-top: 10px;
  margin-bottom: 16px;
  font-weight: 600;
}
.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  gap: 20px;
}
.vertical {
  flex-direction: column;
}
