.expandableTextContainer {
  position: relative;
}

.expandableText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(1.5em * 3);
}

.expandableText.clamped {
  max-height: none;
}

.expandButton {
  display: flex;
  align-items: center;
}

.expandText {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}
