.cardGrid {
  display: grid;
  gap: 16px;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.card {
  background: var(--card-bg-color);
  padding: 8px 16px 16px 16px;
  border-radius: 6px;
  .title {
    font-weight: 500;
    font-size: var(--heading-font-size);
    margin: 8px 0px 8px 0px;
  }
  .desc {
    font-size: 14px;
    color: var(--secondary-text-color);
    font-weight: 400;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  margin-top: 8px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: var(--card-bg-color);
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}
.embla__dot__selected {
  background: var(--primary-color);
}
