.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .card {
    background: var(--card-bg-color);
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);

    .question {
      cursor: pointer !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.8rem 1rem;
      border-radius: 0.5rem;
      text-align: left;
      font-size: 16px;
      color: var(--primary-text-color);

      font-weight: 500;
      .accordionIconOpen {
        transform: rotate(180deg);
      }
      .text {
        width: 100%;
        cursor: pointer;
        padding-right: 1rem;
      }
      .accordionIcon {
        height: 16px;
        width: 16px;
        align-self: center;
        transition: all 300ms ease-in-out;
      }
    }
    .answer {
      font-size: "16px";
      color: var(--secondary-text-color);
      font-weight: 400;
      display: none;
      padding: 0;
    }
    .show.answer {
      padding: 0.2rem 1rem 0.8rem 1rem;
      display: block;
    }
  }
}
.title {
  font-weight: 500;
  font-size: var(--title-font-size);
  margin: 16px 0px 8px 0px;
  text-align: center;
  margin-bottom: 1rem;
}
.heading {
  font-weight: 500;
  font-size: var(--heading-font-size);
  margin: 16px 0px 8px 0px;
}
.description {
  font-size: var(--default-font-size);
  color: var(--secondary-text-color);
  text-align: justify;
}

//transition classes
.enter {
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.enterFrom {
  transform: scale(0.95);
  opacity: 0;
}

.enterTo {
  transform: scale(1);
  opacity: 1;
}

.leave {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.leaveFrom {
  transform: scale(1);
  opacity: 1;
}

.leaveTo {
  transform: scale(0.95);
  opacity: 0;
}
