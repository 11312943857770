.container {
  position: relative;
  z-index: 0;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  aspect-ratio: 1 / 1;
  transition: all 0.3s cubic-bezier(0.22, 0.68, 0, 2.5);
  .image {
    position: absolute;
    border-radius: 6px;
    will-change: scroll-position;
    background: rgba(255, 255, 255, 0.07);
    transition: all 1.2s cubic-bezier(0.22, 0.68, 0, 1.5);
  }
  .text {
    position: absolute;
    inset: 0;
    padding: 0.5rem 1rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    text-shadow: 0px 0px 20px #000000;
    transition: background-color 0.3s cubic-bezier(0.22, 0.68, 0, 1.5),
      opacity 1s cubic-bezier(0.22, 0.68, 0, 1.5);
    background: linear-gradient(to top, #000000bb, #00000044 30%, transparent);
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }
  &:hover {
    transform: translateY(-3px);
    .text {
      opacity: 1;
    }
    .image {
      transform: scale(1.05);
    }
  }
}
