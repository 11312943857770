.primary {
  color: var(--primary-text-color);
}
.secondary {
  color: var(--secondary-text-color);
}
.tertiary {
  color: var(--primary-color);
}

.title {
  font-weight: 600;
  font-size: var(--title-font-size);
  margin: 16px 0px 8px 0px;
}
.titleNoMargin {
  font-weight: 600;
  font-size: var(--title-font-size);
}
.subTitle {
  font-weight: 500;
  font-size: var(--heading-font-size);
  margin: 16px 0px 8px 0px;
}

.normal {
  font-weight: 400;
  font-size: var(--default-font-size);
  margin: 16px 0px 8px 0px;
}

.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
