.title {
  font-weight: 500;
  font-size: 20px;
  margin: 16px 0px 8px 0px;
}
.heading {
  font-weight: 500;
  font-size: var(--heading-font-size);
  margin: 16px 0px 8px 0px;
}
.description {
  font-size: var(--default-font-size);
  color: var(--secondary-text-color);
  text-align: justify;
}
.list {
  list-style: decimal;
  li {
    list-style-position: inside;
  }
}
.listNoIcon {
  list-style-type: "\2714\0020";
  li {
    margin-bottom: 0.5rem;
  }
  &::marker {
    margin-right: 0px;
  }
}
.card {
  background: var(--card-bg-color);
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 6px;
  margin: 1rem 0rem;
  .title {
    font-weight: 500;
    font-size: var(--heading-font-size);
    margin: 8px 0px 8px 0px;
  }
  .desc {
    font-size: var(--paragraph-font-size);
    color: var(--secondary-text-color);
    text-align: justify;
    font-weight: 400;
  }
}
